import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Inbox from '../components/inbox/Inbox';
import SuspenseLoader from '../components/shared/suspense-loader/SuspenseLoader';
import { isJWTValid } from '../shared/helpers/helpers';
import { UrlParams } from '../shared/models/generic';
import { dashboardSlice } from '../shared/store/dashboardSlice.ts';
import {
  clearLockListener,
  getRealTimeCounters,
  getTagCounts,
  listenForActionTypes,
  listenForActiveInbox,
  listenForApprovalChecks,
  listenForDocTypes,
  listenForEntityTypes,
  listenForMetadata,
  listenForPendingDocs,
  listenForTagTypes,
  setLockListener,
} from '../shared/store/inboxSlice';
import { getSidebarWidth } from '../shared/store/settingsSlice';
import { auth } from '../shared/store/setup/firebase-setup';
import { useDispatch, useSelector } from '../shared/store/store';
import { unsubAll } from '../shared/store/subsSlice';

const HomeContainer: React.FC = () => {
  const { inboxId }: UrlParams = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isHistorical = location.pathname.includes('historical');

  const userAccount = useSelector((state) => state.user.userAccount);
  const inboxes = useSelector((state) => state.user.inboxes);
  const inboxIds = useSelector((state) => state.user.userAccount.inboxes);
  const activeDocumentType = useSelector((state) => state.inbox.activeDocumentType);
  const currentInbox = useSelector((state) => state.inbox.currentInbox);
  const tenantId = useSelector((state) => state.tenant.tenantId);
  const [tokenValue, setTokenValue] = useState<string>();
  const [authInfo, setAuthInfo] = useState<{ accessToken: string; expirationTime: number }>();

  useEffect(() => {
    auth.onIdTokenChanged(async (e) => {
      if (!e) return;
      const token = await e.getIdToken();
      setAuthInfo({ accessToken: token, expirationTime: e['stsTokenManager'].expirationTime });
    });
  }, [auth]);

  useEffect(() => {
    if (tenantId === 'demo-tu27c') {
      dispatch(dashboardSlice.actions.setDemoMode(true));
    }
  }, [dispatch, tenantId]);

  useEffect(() => {
    if (!tokenValue) {
      auth.currentUser.getIdToken().then((res) => {
        setTokenValue(res);
        setAuthInfo({
          accessToken: res,
          expirationTime: auth.currentUser['stsTokenManager'].expirationTime,
        });
      });
    }
  }, [tokenValue]);

  const filteredIds = useMemo(() => {
    return inboxIds.filter((e) => inboxes.find((ib) => ib.id === e));
  }, [inboxIds, inboxes]);

  useEffect(() => {
    if (filteredIds?.length > 0) {
      if (!inboxId) {
        if (userAccount.isAdmin) {
          navigate(`/inbox/${filteredIds[0]}/dashboard`, {
            replace: true,
          });
        } else {
          navigate(`/inbox/none`);
        }
      } else {
        if (filteredIds.findIndex((item) => item === inboxId) !== -1) {
          dispatch(listenForActiveInbox(inboxId));
        }
      }
    }
  }, [dispatch, inboxId, inboxIds, userAccount, navigate, filteredIds]);

  useEffect(() => {
    if (currentInbox && currentInbox.id != null) {
      dispatch(listenForPendingDocs());
      dispatch(listenForDocTypes());
      dispatch(listenForApprovalChecks());
      dispatch(listenForMetadata());
      dispatch(listenForActionTypes());
      dispatch(listenForEntityTypes());
      dispatch(listenForTagTypes());
    }
  }, [currentInbox, dispatch]);

  useEffect(() => {
    dispatch(getSidebarWidth());
  }, [dispatch]);

  const getCounters = useCallback(() => {
    if (!isJWTValid(authInfo.expirationTime - 10000) || !inboxId) {
      getAuth().currentUser.getIdToken(true);
    } else {
      dispatch(getRealTimeCounters(authInfo, inboxId, inboxId, isHistorical));
      dispatch(getTagCounts(authInfo, inboxId, activeDocumentType, isHistorical));
    }
  }, [authInfo, dispatch, inboxId, activeDocumentType, isHistorical]);

  useEffect(() => {
    if (!authInfo) return;
    getCounters();

    const counterInterval = setInterval(() => {
      getCounters();
    }, 1000);

    return () => clearInterval(counterInterval);
  }, [authInfo, getCounters]);

  useEffect(() => {
    if (inboxId) dispatch(setLockListener(inboxId));

    return () => {
      dispatch(clearLockListener());
    };
  }, [dispatch, inboxId]);

  useEffect(() => {
    return () => {
      dispatch(unsubAll());
    };
  }, [dispatch]);
  //
  // useEffect(() => {
  //   if (userAccount?.email && intercomHash) {
  //     boot({
  //       languageOverride: userAccount.preferences.language ?? null,
  //       email: userAccount.email,
  //       actionColor: '#0085FF',
  //       backgroundColor: '#0085FF',
  //       userHash: intercomHash,
  //       customAttributes: {
  //         tenant: tenantName,
  //       },
  //     });
  //   }
  // }, [boot, userAccount, intercomHash, tenantName]);

  if (!userAccount.email) {
    return <SuspenseLoader name={'home-placeholder-01'} fullPage />;
  }
  return <Inbox />;
};

export default HomeContainer;
