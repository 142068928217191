import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ArrowBackIcon } from '../../../shared/assets/svg/arrow-back-icon.svg';
import { ReactComponent as BounceIcon } from '../../../shared/assets/svg/bounce-icon.svg';
import { ReactComponent as DividerVertical } from '../../../shared/assets/svg/divider-vertical.svg';
import { ReactComponent as InboxIcon } from '../../../shared/assets/svg/inbox-icon-old.svg';
import { sleep, usePrevious } from '../../../shared/helpers/helpers';
import { useModal } from '../../../shared/hooks/useModal';
import { CopyStructure, DocumentDetails } from '../../../shared/models/document';
import { UrlParams } from '../../../shared/models/generic';
import { DocTypeExtended, DocTypeSummary } from '../../../shared/models/inbox';
import documentSlice, {
  changeDocInbox,
  createDocumentCopy,
  finalDocInStructureSelector,
  getCopy,
  getDocument,
  isMutationSelector,
  processEntities,
} from '../../../shared/store/documentSlice';
import { bounceDocument, deleteDocument, patchDocument } from '../../../shared/store/inboxSlice';
import { labelerSlice, setSortedDocumentEntities } from '../../../shared/store/labelerSlice';
import { useDispatch, useSelector } from '../../../shared/store/store';
import s from '../../../shared/styles/component/document/document.module.scss';
import HeaderProfile from '../../header/profile/HeaderProfile';
import ApprovalChecks from '../../shared/approval-checks/ApprovalChecks';
import ConfirmationDialog from '../../shared/confirmation-dialog/ConfirmationDialog';
import Tooltip from '../../shared/tooltip/Tooltip';
import DocumentBounceModal from '../bounce-modal/DocumentBounceModal';
import DocumentMutateModal from '../split/DocumentMutateModal.tsx';
import DocumentInboxSwitchModal from '../type-selector/DocumentInboxSwitchModal';
import DocumentTypeSwitchModal from '../type-selector/DocumentTypeSwitchModal';
import DocumentHeaderBadge from './DocumentHeaderBadge';
import DocumentNotes from './DocumentNotes';
import DocumentTagDropdown from './DocumentTagDropdown';

interface Props {
  activeDocument: DocumentDetails;
  activeInboxName?: string;
  routeDocType: DocTypeSummary;
  handleNext: (mustReplace?: boolean) => void;
  handlePrevious: (mustReplace?: boolean) => void;
}

export let canUpdateValue = true;

const DocumentHeader: React.FC<Props> = ({ activeDocument, activeInboxName, routeDocType, handleNext }) => {
  const copyStructure = useSelector((state) => state.document.copyStructure);
  const finalDocInStructure = useSelector(finalDocInStructureSelector);
  const isMutation = useSelector(isMutationSelector);
  const nextDocumentId = useSelector((state) => state.document.nextDocumentId);
  // const nextDocumentId = useSelector((state) => state.document.isT);
  const tagTypes = useSelector((state) => state.settings.tagTypes);
  const docTypeSettings = useSelector((state) => state.settings.docTypeSettings);
  const pageImagesMap = useSelector((state) => state.document.pageImagesMap);

  const typeMap = useSelector((state) => state.inbox.typeMap);
  const docTypesSub = useSelector((state) => state.subs.docTypesSub);
  const selectedCopyId = useSelector((state) => state.document.selectedCopyId);
  const deletingCopyId = useSelector((state) => state.document.deletingCopyId);
  const isCreatingCopy = useSelector((state) => state.document.isCreatingCopy);
  const inboxSettings = useSelector((state) => state.inbox.currentInbox.settings);
  const copySub = useSelector((state) => state.subs.copySub);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const historical = location.pathname.includes('historical');
  const { docId, inboxId }: UrlParams = useParams();
  const { showDialog, showModal, closeModal } = useModal();
  const { t } = useTranslation();
  const { width: copiesWidth, ref: copiesRef } = useResizeDetector({
    refreshRate: 1,
    refreshMode: 'throttle',
  });

  const [staticMap, setStaticMap] = useState(typeMap);
  const [activeDocType, setActiveDocType] = useState<DocTypeExtended>(null);
  const [canSwitchToCopy, setCanSwitchToCopy] = useState(false);
  const prevStructure: CopyStructure = usePrevious(copyStructure);

  const handleBounce = (value: Record<string, any>) => {
    dispatch(bounceDocument(activeDocument.id, inboxId, value, isMutation)).then(() => {
      if (!isMutation) handleNext();
    });
  };
  const allCopiesApproved = useMemo(
    () =>
      activeDocument ? (activeDocument?.nMutations ? activeDocument?.nActiveMutations === 0 : true) : false,
    [activeDocument]
  );

  useEffect(() => {
    if (canUpdateValue && typeMap.normal.size > 0) {
      setStaticMap(typeMap);
      canUpdateValue = false;
    }
  }, [typeMap]);

  useEffect(() => {
    if (activeDocument && staticMap?.normal && Object.entries(staticMap.normal).length > 0) {
      setActiveDocType({
        docType: {
          id: activeDocument.docTypeId,
          name: staticMap.normal.get(activeDocument.docTypeId),
        },
        subType: activeDocument.docSubtypeId
          ? {
              id: activeDocument.docSubtypeId,
              name: staticMap.normal.get(`${activeDocument.docTypeId}-${activeDocument.docSubtypeId}`),
            }
          : {
              id: null,
              name: null,
            },
      });
    }
  }, [activeDocument, staticMap]);

  const handleSelectCopy = useCallback(
    (documentId: string) => {
      if (!copyStructure) return;
      let copyDoc = copyStructure.copyList.find((e) => e.id === documentId);
      const parentDocId = copyDoc ? copyStructure.originalDoc.id : null;
      copyDoc = copyDoc || copyStructure.originalDoc;

      dispatch(documentSlice.actions.setActiveDocument(null));
      dispatch(documentSlice.actions.setActiveDocument(copyDoc));
      dispatch(labelerSlice.actions.setTempEntity(null));

      if (copySub) copySub();
      if (parentDocId) {
        dispatch(getCopy(documentId, parentDocId));
      } else {
        dispatch(documentSlice.actions.setSelectedCopyId(copyStructure.originalDoc.id));
        dispatch(documentSlice.actions.setActiveDocument(copyStructure.originalDoc));
        const processed = processEntities(
          copyStructure.originalDoc,
          copyStructure.originalDoc.entities?.length > 0 ? copyStructure.originalDoc.entities : []
        );
        dispatch(setSortedDocumentEntities(processed));
      }
    },
    [copyStructure, copySub, dispatch]
  );

  const currentThumbs = useMemo(() => {
    const current = pageImagesMap[docId];
    return current;
  }, [docId, pageImagesMap]);

  useEffect(() => {
    return () => {
      if (copySub) copySub();
    };
  }, [copySub]);

  const handleSelectType = async ({ docType, subType }: DocTypeExtended, changeAll: boolean) => {
    const updateDocument = (id: string, isMutation: boolean) => {
      return dispatch(
        patchDocument(
          id,
          inboxId,
          {
            doc_type_id: docType.id,
            doc_subtype_id: subType?.id || null,
          },
          isMutation
        )
      );
    };

    dispatch(labelerSlice.actions.setActiveEntityPair(null));
    dispatch(documentSlice.actions.setIsProcessing(true));

    const promises = [];

    if (!changeAll) {
      promises.push(updateDocument(activeDocument.id, isMutation));
    } else {
      const { originalDoc, copyList } = copyStructure;
      promises.push(updateDocument(originalDoc.id, false));
      copyList.forEach((copy) => {
        if (copy.action) return;
        promises.push(updateDocument(copy.id, true));
      });
    }

    await Promise.all(promises).then(() => {
      closeModal();
    });
  };

  const handleCreateNewCopy = () => {
    if (inboxSettings.documentTransform) {
      showModal(
        <DocumentMutateModal
          copyStructure={copyStructure}
          currentDocument={activeDocument}
          currentThumbs={currentThumbs}
          inboxId={inboxId}
          setCanSwitchToCopy={setCanSwitchToCopy}
        />
      );
    } else {
      dispatch(createDocumentCopy(copyStructure.originalDoc.id, inboxId));
      setCanSwitchToCopy(true);
    }
  };

  useEffect(() => {
    if (prevStructure && copyStructure && canSwitchToCopy) {
      if (prevStructure.copyList?.length !== copyStructure.copyList?.length) {
        const copy = copyStructure.copyList.at(-1);
        handleSelectCopy(copy.id);
        setCanSwitchToCopy(false);
      }
    }
  }, [canSwitchToCopy, copyStructure, handleSelectCopy, prevStructure]);

  const handleChangeDoctype = () => {
    if (activeDocument.action) return;
    showModal(
      <DocumentTypeSwitchModal
        suggestions={activeDocument ? activeDocument.alternativeClassificationResults : []}
        currentDocType={
          {
            docTypeId: activeDocument?.docTypeId,
            subTypeId: activeDocument?.docSubtypeId,
          } as DocTypeSummary
        }
        handleConfirm={handleSelectType}
        hasMutations={activeDocument.nActiveMutations > 0 || activeDocument.mutationId != null}
      />
    );
  };

  const handleSelectInbox = (newInboxId: string) => {
    dispatch(labelerSlice.actions.setActiveEntityPair(null));
    dispatch(documentSlice.actions.setCopyStructure(null));
    dispatch(documentSlice.actions.setSelectedCopyId(null));
    dispatch(documentSlice.actions.setIsProcessing(true));

    sleep(500).then(() => {
      dispatch(changeDocInbox(copyStructure.originalDoc.id, inboxId, newInboxId));
      if (docTypesSub) docTypesSub();
      canUpdateValue = true;
      closeModal();
      handleNext(true);
    });
  };
  const handleSelectTag = (tagId: string) => {
    dispatch(patchDocument(docId, inboxId, { tag_type_id: tagId }));
  };

  const getReturnPath = useMemo(() => {
    let returnPath: any;
    if (!routeDocType.docTypeId) {
      returnPath = '';
    } else if (routeDocType.subTypeId) {
      returnPath = {
        docType: routeDocType.docTypeId,
        subType: routeDocType.subTypeId,
      };
    } else {
      returnPath = { docType: routeDocType.docTypeId };
    }
    return queryString.stringify(returnPath);
  }, [routeDocType]);

  const handleDeletion = () => {
    let text = t('document:deletion.original');
    if (isMutation) {
      text = t('document:deletion.copy');
    } else if (copyStructure?.copyList?.length > 0) {
      text += '. \n ' + t('document:deletion.extra');
    }
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          dispatch(deleteDocument(activeDocument.id, inboxId, isMutation)).then(() => {
            if (finalDocInStructure || !isMutation) {
              if (nextDocumentId) {
                dispatch(documentSlice.actions.setCopyStructure(null));
                dispatch(documentSlice.actions.setActiveDocument(null));
                handleNext();
              } else {
                dispatch(documentSlice.actions.setActiveDocument(null));
                dispatch(documentSlice.actions.setCopyStructure(null));
                historical ? navigate(`/inbox/${inboxId}/historical`) : navigate(`/inbox/${inboxId}`);
              }
            } else {
              dispatch(getDocument(docId));
            }
          });
        }}
        text={text}
        title={isMutation ? t('document:deletion.deleteCopy') : t('document:deletion.deleteOriginal')}
      />
    );
  };

  const listStyle = useMemo(() => {
    if (copiesRef.current && copiesWidth) {
      const list = copiesRef.current as HTMLDivElement;
      if (list.clientWidth !== list.scrollWidth) {
        return { marginBottom: -10, paddingBottom: 2 } as CSSProperties;
      }
    }
    return {};
  }, [copiesRef, copiesWidth]);

  const mutationsLength = useMemo(() => {
    if (copyStructure?.copyList) {
      return copyStructure.copyList.length;
    }
    return 0;
  }, [copyStructure]);

  const isLowConfidence = useMemo(() => {
    if (activeDocument) {
      const conf = activeDocument.confidence;
      const docType = docTypeSettings.find((dt) => dt.docTypeId === activeDocument.docTypeId);
      if (docType) {
        return conf < docType.settings.approvalThreshold;
      }
    }
    return false;
  }, [activeDocument, docTypeSettings]);

  const isTagsVisible = useMemo(() => {
    if (tagTypes && activeDocument && !inboxSettings.labelingMode) {
      if (historical) {
        return activeDocument?.tagTypeId !== null;
      } else {
        return true;
      }
    }
    return false;
  }, [activeDocument, historical, inboxSettings.labelingMode, tagTypes]);

  return (
    <header className={s.header}>
      <div className={s.header__main}>
        <button
          data-testid={'header-back-button'}
          className={s.back_button}
          onClick={() => {
            if (historical) {
              navigate(`/inbox/${inboxId}/historical?${getReturnPath}`);
            } else {
              navigate(`/inbox/${inboxId}?${getReturnPath}`);
            }
          }}
        >
          <ArrowBackIcon data-testid="arrow-back" />
        </button>
        {activeDocument?.approvalChecks && <ApprovalChecks document={activeDocument} />}
        {historical && activeDocument?.confidence && (
          <span
            className={clsx(
              s.header__confidence,
              { [s.header__confidence_good]: !isLowConfidence },
              { [s.header__confidence_warn]: isLowConfidence }
            )}
          >
            {(activeDocument?.confidence * 100).toFixed(2)} %
          </span>
        )}

        <h1 className={s.header__title} data-hj-suppress>
          {activeDocument ? activeDocument.name : 'Loading...'}
        </h1>
        {isTagsVisible && (
          <>
            <DividerVertical className={s.header__divider} />
            <DocumentTagDropdown
              tagTypes={tagTypes}
              handleSelectTag={!historical && handleSelectTag}
              activeTagId={activeDocument?.tagTypeId}
            />
          </>
        )}

        {activeDocType && activeDocument && (
          <>
            {activeDocument?.docTypeId !== '@PB_DELETE' && (
              <>
                <DividerVertical className={s.header__divider} />
                <div ref={copiesRef} style={listStyle} className={clsx(s.copy_selector_list, s.fade)}>
                  <DocumentHeaderBadge
                    handleDeletion={handleDeletion}
                    handleClick={() => handleSelectCopy(copyStructure.originalDoc.id)}
                    handleEdit={handleChangeDoctype}
                    isActive={
                      copyStructure?.originalDoc ? copyStructure.originalDoc.id === selectedCopyId : false
                    }
                    docType={{
                      docType: activeDocType.docType ? activeDocType.docType : null,
                      subType: activeDocType.subType.name ? activeDocType.subType : null,
                    }}
                    name={t('document:original')}
                    isApproved={copyStructure?.originalDoc?.action != null}
                    isDeleting={deletingCopyId === activeDocument.id}
                    isLowConfidence={isLowConfidence}
                  />
                  {copyStructure?.copyList?.map((copy, i) => {
                    const docType = {
                      docType: {
                        id: copy.docTypeId,
                        name: staticMap.normal.get(copy.docTypeId),
                      },
                      subType: null,
                    };
                    if (copy.docSubtypeId) {
                      docType.subType = {
                        id: copy.docSubtypeId,
                        name: staticMap.normal.get(`${copy.docTypeId}-${copy.docSubtypeId}`),
                      };
                    }
                    return (
                      <DocumentHeaderBadge
                        key={copy.id}
                        handleDeletion={handleDeletion}
                        handleClick={() => handleSelectCopy(copy.id)}
                        handleEdit={handleChangeDoctype}
                        docType={docType}
                        name={`Copy ${i + 1}`}
                        isActive={selectedCopyId === copy.id}
                        isApproved={copy.action != null}
                        isDeleting={deletingCopyId === copy.id}
                      />
                    );
                  })}
                  {inboxSettings?.documentCopy &&
                    !inboxSettings.labelingMode &&
                    mutationsLength < 10 &&
                    !historical && (
                      <DocumentHeaderBadge
                        isCreating
                        isPending={isCreatingCopy}
                        name=""
                        docType={null}
                        handleClick={handleCreateNewCopy}
                        handleDeletion={null}
                      />
                    )}
                </div>
              </>
            )}

            {!historical && (
              <>
                <DividerVertical className={s.header__divider} />

                <button
                  className={clsx(s.header_button, s.fade)}
                  data-tour={'inbox-select'}
                  onClick={() => {
                    showModal(
                      <DocumentInboxSwitchModal currentInboxId={inboxId} handleConfirm={handleSelectInbox} />
                    );
                  }}
                >
                  <InboxIcon />

                  <span>
                    {t('document:inbox')}: {activeInboxName ? activeInboxName : ''}
                  </span>
                </button>
              </>
            )}

            {inboxSettings.bounce &&
              !inboxSettings.labelingMode &&
              !activeDocument.action &&
              (activeDocument.id === docId && !allCopiesApproved ? (
                <>
                  <DividerVertical className={s.header__divider} />

                  <Tooltip content={<span>Finish copies first</span>}>
                    <button style={{ opacity: 0.5 }} className={clsx(s.header_button)}>
                      <BounceIcon />
                      <span>{t('document:bounceAction.button')}</span>
                    </button>
                  </Tooltip>
                </>
              ) : (
                <>
                  <DividerVertical className={s.header__divider} />
                  <button
                    className={clsx(s.header_button, s.fade)}
                    onClick={() => {
                      showModal(<DocumentBounceModal handleBounce={handleBounce} />);
                    }}
                  >
                    <BounceIcon />
                    <span>{t('document:bounceAction.button')}</span>
                  </button>
                </>
              ))}
          </>
        )}
      </div>
      <DocumentNotes notes={activeDocument?.notes ?? []} />
      <HeaderProfile hasName={false} isLight />
    </header>
  );
};

export default DocumentHeader;
