import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { Pulsar } from '@uiball/loaders';

import { bounceFieldClientToRaw } from '../../../shared/helpers/converters/bouncefield.ts';
import { docTypeClientToRaw } from '../../../shared/helpers/converters/doctype.ts';
import { fieldTypeClientToRaw } from '../../../shared/helpers/converters/fieldtype.ts';
import { inboxClientToRaw } from '../../../shared/helpers/converters/inbox.ts';
import { metadataClientToRaw } from '../../../shared/helpers/converters/metadata.ts';
import { tagClientToRaw } from '../../../shared/helpers/converters/tag.ts';
import { ReactComponent as CheckMarkIcon } from '../../../shared/assets/svg/checkmark-icon.svg';
import { ReactComponent as CrossIcon } from '../../../shared/assets/svg/cross-icon.svg';
import {
  AdminState,
  patchInbox,
  putActionType,
  putActionTypeOption,
  putDocType,
  putEntityType,
  putMetadataType,
  putTagType,
} from '../../../shared/store/adminSlice.ts';
import s from '../../../shared/styles/component/admin/admin-import-modal.module.scss';
import Checkbox from '../../shared/checkbox/Checkbox.tsx';
import Modal from '../../shared/modal/Modal.tsx';

interface Props {
  inboxId: string;
}
interface TypeStatus {
  id: string;
  name: string;
  status: Status;
}

enum Status {
  default = 'default',
  loading = 'loading',
  success = 'success',
  error = 'error',
}

const AdminImportModal: React.FC<Props> = ({ inboxId }) => {
  const [newFile, setNewFile] = useState<File>();
  const inputRef = useRef<any>();
  const [uploadStep, setUploadStep] = useState(0);
  const [isChecked, setIsChecked] = useState({
    inboxSettings: true,
    docTypes: true,
    entityTypes: true,
    actionTypes: true,
    metadataTypes: true,
    tagTypes: true,
  });
  const [status, setStatus] = useState({
    inboxSettings: Status.default,
    docTypes: Status.default,
    entityTypes: Status.default,
    actionTypes: Status.default,
    metadataTypes: Status.default,
    tagTypes: Status.default,
  });

  const [typeStatuses, setTypeStatuses] = useState<{
    [key: string]: TypeStatus[];
  }>({});

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setNewFile(e.target.files[0]);
      setUploadStep(1);
    }
  };

  const [parsedData, setParsedData] = useState<AdminState | null>(null);
  useEffect(() => {
    if (newFile) {
      const fileReader = new FileReader();
      fileReader.readAsText(newFile, 'UTF-8');
      fileReader.onload = (e) => {
        const info = JSON.parse(e.target.result as string) as AdminState;
        setParsedData(info);

        // Initialize typeStatuses based on parsedData
        const initialTypeStatuses = {
          docTypes:
            info.inboxDocTypes?.map((dt) => ({ id: dt.id, name: dt.name, status: Status.default })) || [],
          entityTypes:
            info.inboxEntityTypes?.map((et) => ({ id: et.id, name: et.name, status: Status.default })) || [],
          actionTypes:
            info.inboxActionTypes?.map((at) => ({ id: at.id, name: at.name, status: Status.default })) || [],
          metadataTypes:
            info.inboxMetadataTypes?.map((mt) => ({ id: mt.id, name: mt.name, status: Status.default })) ||
            [],
          tagTypes:
            info.inboxTagTypes?.map((tt) => ({ id: tt.id, name: tt.name, status: Status.default })) || [],
        };
        setTypeStatuses(initialTypeStatuses);
      };
    }
  }, [newFile]);

  const handleUploadButton = () => {
    if (newFile) {
      setUploadStep(2);
    } else {
      if (inputRef.current) {
        inputRef.current.click();
      }
    }
  };

  const toggleCheckbox = (key: keyof typeof isChecked) => {
    setIsChecked((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const updateStatus = (key: string, status: Status) => {
    setStatus((prev) => ({
      ...prev,
      [key]: status,
    }));
  };

  const updateTypeStatus = (category: string, typeId: string, newStatus: Status) => {
    setTypeStatuses((prev) => {
      const updatedCategory = prev[category].map((item) => {
        if (item.id === typeId) {
          return { ...item, status: newStatus };
        }
        return item;
      });
      return { ...prev, [category]: updatedCategory };
    });
  };

  const handleImportData = async () => {
    if (isChecked.actionTypes && parsedData.inboxActionTypes) {
      for (const actionType of parsedData.inboxActionTypes) {
        updateTypeStatus('actionTypes', actionType.id, Status.loading);
        const data = bounceFieldClientToRaw(actionType);
        const optionsCopy = { ...data.options };
        putActionType(inboxId, data, actionType.id)
          .then((res) => {
            const newId = res.data['id'];
            if (optionsCopy) {
              console.log(optionsCopy);
              const promiseList = [];
              for (const [k, v] of Object.entries(optionsCopy)) {
                const result = putActionTypeOption(inboxId, newId, { id: k, ...v });
                promiseList.push(result);
              }
              Promise.all(promiseList).then(() => {
                updateTypeStatus('actionTypes', actionType.id, Status.success);
              });
            } else {
              updateTypeStatus('actionTypes', actionType.id, Status.success);
            }
          })
          .catch(() => {
            updateTypeStatus('actionTypes', actionType.id, Status.error);
          });
      }
    }
    if (isChecked.entityTypes && parsedData.inboxEntityTypes) {
      for (const fieldType of parsedData.inboxEntityTypes) {
        updateTypeStatus('entityTypes', fieldType.id, Status.loading);
        const data = fieldTypeClientToRaw(fieldType);
        putEntityType(inboxId, data, fieldType.id)
          .then(() => {
            updateTypeStatus('entityTypes', fieldType.id, Status.success);
          })
          .catch(() => {
            updateTypeStatus('entityTypes', fieldType.id, Status.error);
          });
      }
    }

    if (isChecked.docTypes && parsedData.inboxDocTypes) {
      for (const docType of parsedData.inboxDocTypes) {
        if (docType.id !== '@PB_NOTYPE') {
          updateTypeStatus('docTypes', docType.id, Status.loading);
          const data = docTypeClientToRaw(docType);
          putDocType(inboxId, data, docType.id)
            .then(() => {
              updateTypeStatus('docTypes', docType.id, Status.success);
            })
            .catch(() => {
              updateTypeStatus('docTypes', docType.id, Status.error);
            });
        }
      }
    }

    if (isChecked.inboxSettings && parsedData.inboxes) {
      const inboxToCopy = parsedData.inboxes.find((e) => e.id === parsedData.activeInboxId);
      if (inboxToCopy) {
        updateStatus('inboxSettings', Status.loading);
        const data = inboxClientToRaw(inboxToCopy);
        delete data.settings.name;
        data.id = inboxId;
        patchInbox(data)
          .then(() => {
            updateStatus('inboxSettings', Status.success);
          })
          .catch(() => {
            updateStatus('inboxSettings', Status.error);
          });
      }
    }

    if (isChecked.metadataTypes && parsedData.inboxMetadataTypes) {
      for (const metadataType of parsedData.inboxMetadataTypes) {
        updateTypeStatus('metadataTypes', metadataType.id, Status.loading);
        const data = metadataClientToRaw(metadataType);
        putMetadataType(inboxId, data, metadataType.id)
          .then(() => {
            updateTypeStatus('metadataTypes', metadataType.id, Status.success);
          })
          .catch(() => {
            updateTypeStatus('metadataTypes', metadataType.id, Status.error);
          });
      }
    }

    if (isChecked.tagTypes && parsedData.inboxTagTypes) {
      for (const tagType of parsedData.inboxTagTypes) {
        updateTypeStatus('tagTypes', tagType.id, Status.loading);
        const data = tagClientToRaw(tagType);
        putTagType(inboxId, data, tagType.id)
          .then(() => {
            updateTypeStatus('tagTypes', tagType.id, Status.success);
          })
          .catch(() => {
            updateTypeStatus('tagTypes', tagType.id, Status.error);
          });
      }
    }
  };

  return (
    <Modal>
      <div className={s.container}>
        <div className={s.header}>Admin Import</div>
        <div className={s.content}>
          {uploadStep === 0 && (
            <button
              onClick={handleUploadButton}
              data-testid={'upload-table'}
              type={'button'}
              className={s.button}
            >
              {'Select File'}
            </button>
          )}

          <input
            ref={inputRef}
            onChange={handleFileSelect}
            className={s.file_input}
            accept={'application/json'}
            type="file"
          />
          <div>
            {parsedData && (
              <div className={s.data}>
                {parsedData.inboxes && parsedData.inboxes.find((e) => e.id === parsedData.activeInboxId) && (
                  <div className={s.row} style={{ minWidth: '100%' }}>
                    <div className={s.row_header}>
                      <span>Inbox Configuration</span>
                      <div className={s.status}>
                        {status.inboxSettings === Status.default && (
                          <Checkbox
                            onClick={() => toggleCheckbox('inboxSettings')}
                            checked={isChecked.inboxSettings}
                          />
                        )}
                        {status.inboxSettings === Status.success && (
                          <CheckMarkIcon style={{ color: '#91C500', width: 16, height: 'auto' }} />
                        )}
                        {status.inboxSettings === Status.error && <CrossIcon />}
                        {status.inboxSettings === Status.loading && <Pulsar size={14} />}
                      </div>
                    </div>
                  </div>
                )}
                {parsedData.inboxDocTypes && parsedData.inboxDocTypes.length > 0 && (
                  <div className={s.row}>
                    <div className={s.row_header}>
                      <span>Doctypes ({parsedData.inboxDocTypes.length})</span>
                      {typeStatuses['docTypes'].every((e) => e.status === Status.default) && (
                        <Checkbox onClick={() => toggleCheckbox('docTypes')} checked={isChecked.docTypes} />
                      )}
                      {typeStatuses['docTypes'].every((e) => e.status === Status.success) && (
                        <CheckMarkIcon style={{ color: '#91C500', width: 16, height: 'auto' }} />
                      )}
                      {typeStatuses['docTypes'].every((e) => e.status === Status.error) && <CrossIcon />}
                    </div>
                    <div className={s.list}>
                      {parsedData.inboxDocTypes.map((docType) => {
                        if (docType.id === '@PB_NOTYPE') return null;
                        return (
                          <div className={s.list_item} key={docType.id}>
                            <span>{docType.name}</span>
                            <div className={s.status}>
                              {typeStatuses['docTypes'].find((e) => e.id === docType.id).status ===
                                Status.success && (
                                <CheckMarkIcon style={{ color: '#91C500', width: 16, height: 'auto' }} />
                              )}
                              {typeStatuses['docTypes'].find((e) => e.id === docType.id).status ===
                                Status.error && <CrossIcon />}
                              {typeStatuses['docTypes'].find((e) => e.id === docType.id).status ===
                                Status.loading && <Pulsar size={14} />}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {parsedData.inboxMetadataTypes && parsedData.inboxMetadataTypes.length > 0 && (
                  <div className={s.row}>
                    <div className={s.row_header}>
                      <span>Metadata ({parsedData.inboxMetadataTypes.length})</span>
                      {typeStatuses['metadataTypes'].every((e) => e.status === Status.default) && (
                        <Checkbox
                          onClick={() => toggleCheckbox('metadataTypes')}
                          checked={isChecked.metadataTypes}
                        />
                      )}
                      {typeStatuses['metadataTypes'].every((e) => e.status === Status.success) && (
                        <CheckMarkIcon style={{ color: '#91C500', width: 16, height: 'auto' }} />
                      )}
                      {typeStatuses['metadataTypes'].every((e) => e.status === Status.error) && <CrossIcon />}
                    </div>
                    <div className={s.list}>
                      {parsedData.inboxMetadataTypes.map((tagType) => (
                        <div className={s.list_item} key={tagType.id}>
                          <span>{tagType.name}</span>
                          <div className={s.status}>
                            {typeStatuses['metadataTypes'].find((e) => e.id === tagType.id).status ===
                              Status.success && (
                              <CheckMarkIcon style={{ color: '#91C500', width: 16, height: 'auto' }} />
                            )}
                            {typeStatuses['metadataTypes'].find((e) => e.id === tagType.id).status ===
                              Status.error && <CrossIcon />}
                            {typeStatuses['metadataTypes'].find((e) => e.id === tagType.id).status ===
                              Status.loading && <Pulsar size={14} />}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {parsedData.inboxActionTypes && parsedData.inboxActionTypes.length > 0 && (
                  <div className={s.row}>
                    <div className={s.row_header}>
                      <span>Bounce Fields ({parsedData.inboxActionTypes.length})</span>
                      {typeStatuses['actionTypes'].every((e) => e.status === Status.default) && (
                        <Checkbox
                          onClick={() => toggleCheckbox('actionTypes')}
                          checked={isChecked.actionTypes}
                        />
                      )}
                      {typeStatuses['actionTypes'].every((e) => e.status === Status.success) && (
                        <CheckMarkIcon style={{ color: '#91C500', width: 16, height: 'auto' }} />
                      )}
                      {typeStatuses['actionTypes'].every((e) => e.status === Status.error) && <CrossIcon />}
                    </div>
                    <div className={s.list}>
                      {parsedData.inboxActionTypes.map((actionType) => (
                        <div className={s.list_item} key={actionType.id}>
                          <span>{actionType.name}</span>
                          <div className={s.status}>
                            {typeStatuses['actionTypes'].find((e) => e.id === actionType.id).status ===
                              Status.success && (
                              <CheckMarkIcon style={{ color: '#91C500', width: 16, height: 'auto' }} />
                            )}
                            {typeStatuses['actionTypes'].find((e) => e.id === actionType.id).status ===
                              Status.error && <CrossIcon />}
                            {typeStatuses['actionTypes'].find((e) => e.id === actionType.id).status ===
                              Status.loading && <Pulsar size={14} />}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {parsedData.inboxTagTypes && parsedData.inboxTagTypes.length > 0 && (
                  <div className={s.row}>
                    <div className={s.row_header}>
                      <span>Tags ({parsedData.inboxTagTypes.length})</span>
                      {typeStatuses['tagTypes'].every((e) => e.status === Status.default) && (
                        <Checkbox onClick={() => toggleCheckbox('tagTypes')} checked={isChecked.tagTypes} />
                      )}
                      {typeStatuses['tagTypes'].every((e) => e.status === Status.success) && (
                        <CheckMarkIcon style={{ color: '#91C500', width: 16, height: 'auto' }} />
                      )}
                      {typeStatuses['tagTypes'].every((e) => e.status === Status.error) && <CrossIcon />}
                    </div>
                    <div className={s.list}>
                      {parsedData.inboxTagTypes.map((tagType) => (
                        <div className={s.list_item} key={tagType.id}>
                          <span>{tagType.name}</span>
                          <div className={s.status}>
                            {typeStatuses['tagTypes'].find((e) => e.id === tagType.id).status ===
                              Status.success && (
                              <CheckMarkIcon style={{ color: '#91C500', width: 16, height: 'auto' }} />
                            )}
                            {typeStatuses['tagTypes'].find((e) => e.id === tagType.id).status ===
                              Status.error && <CrossIcon />}
                            {typeStatuses['tagTypes'].find((e) => e.id === tagType.id).status ===
                              Status.loading && <Pulsar size={14} />}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {parsedData.inboxEntityTypes && parsedData.inboxEntityTypes.length > 0 && (
                  <div className={s.row}>
                    <div className={s.row_header}>
                      <span>Fields ({parsedData.inboxEntityTypes.length})</span>
                      {typeStatuses['entityTypes'].every((e) => e.status === Status.default) && (
                        <Checkbox
                          onClick={() => toggleCheckbox('entityTypes')}
                          checked={isChecked.entityTypes}
                        />
                      )}
                      {typeStatuses['entityTypes'].every((e) => e.status === Status.success) && (
                        <CheckMarkIcon style={{ color: '#91C500', width: 16, height: 'auto' }} />
                      )}
                      {typeStatuses['entityTypes'].every((e) => e.status === Status.error) && <CrossIcon />}
                    </div>
                    <div className={s.list}>
                      {parsedData.inboxEntityTypes.map((entityType) => (
                        <div className={s.list_item} key={entityType.id}>
                          <span>{entityType.name}</span>
                          <div className={s.status}>
                            {typeStatuses['entityTypes'].find((e) => e.id === entityType.id).status ===
                              Status.success && (
                              <CheckMarkIcon style={{ color: '#91C500', width: 16, height: 'auto' }} />
                            )}
                            {typeStatuses['entityTypes'].find((e) => e.id === entityType.id).status ===
                              Status.error && <CrossIcon />}
                            {typeStatuses['entityTypes'].find((e) => e.id === entityType.id).status ===
                              Status.loading && <Pulsar size={14} />}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {parsedData && (
              <button
                type={'button'}
                onClick={() => {
                  handleImportData();
                }}
                className={clsx(s.button)}
              >
                Import Selected
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AdminImportModal;
