import { isEqual, throttle } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { DateRangePicker } from 'rsuite';
import { RangeType } from 'rsuite/DateRangePicker';
import { allowedRange } from 'rsuite/esm/DateRangePicker/disabledDateUtils';
import { addDays, subDays } from 'rsuite/esm/utils/dateUtils';
import { isSameDate, usePrevious } from '../../../shared/helpers/helpers.ts';
import { ReactComponent as SearchIcon } from '../../../shared/assets/svg/search-icon.svg';
import { useIntercomBoot } from '../../../shared/hooks/intercomContext.tsx';
import { getHistoricalDocumentIds, inboxSlice } from '../../../shared/store/inboxSlice.ts';
import { useDispatch, useSelector } from '../../../shared/store/store.ts';
import s from '../../../shared/styles/component/inbox/inbox-content.module.scss';
import HeaderProfile from '../../header/profile/HeaderProfile.tsx';
import StyledSelect, { DropdownOption } from '../../shared/dropdown/StyledSelect.tsx';
import InboxTags from '../table/components/InboxTags.tsx';
import usePagination from '../table/hooks/usePagination.tsx';
import InboxTable, { TableColumns } from '../table/InboxTable.tsx';
import InboxTableFooter from '../table/InboxTableFooter.tsx';

interface Props {}

const InboxHistoricalDocuments: React.FC<Props> = () => {
  const { inboxId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentPageIndex } = usePagination();
  const { isIntercomBooted } = useIntercomBoot();
  const { trackEvent } = useIntercom();
  const activeDocType = useSelector((state) => state.inbox.activeDocumentType);
  const documentListOptions = useSelector((state) => state.inbox.documentListOptions);
  const userInboxIds = useSelector((state) => state.user.userAccount.inboxes);
  const currentDocumentList = useSelector((state) => state.inbox.currentDocumentList);
  const count = useSelector((state) => state.inbox.historicalDocumentCount);
  const documentRetentionTime = useSelector(
    (state) => state.inbox.currentInbox.settings.documentRetentionTime
  );
  const pageSize = documentListOptions.pageSize;
  const pageCount = Math.max(1, Math.ceil(count / pageSize)) || 1;
  const prevDocListOptions = usePrevious(documentListOptions);
  const prevPageIndex = usePrevious(currentPageIndex);
  const [activeDateRange, setActiveDateRange] = useState<[Date, Date]>([new Date(), new Date()]);

  const getDocs = useCallback(
    throttle(() => dispatch(getHistoricalDocumentIds(inboxId, currentPageIndex + 1)), 1000),
    [dispatch, inboxId, currentPageIndex, documentListOptions]
  );

  useEffect(() => {
    if (isIntercomBooted) {
      trackEvent('viewed-history');
    }
  }, [isIntercomBooted, trackEvent]);

  const actionOptions: DropdownOption[] = [
    { label: t('document:actions.all'), value: 'all' },
    { label: t('document:actions.approve'), value: 'approve' },
    { label: t('document:actions.bounce'), value: 'bounce' },
    { label: t('document:actions.delete'), value: 'delete' },
  ];
  const [actionValue, setActionValue] = useState({ label: t('document:actions.all'), value: 'all' });
  const predefinedBottomRanges: RangeType[] = [
    {
      label: t(`home:dashboard.rangeDay`),
      value: [new Date(), new Date()],
    },
    {
      label: t(`home:dashboard.rangeYesterday`),
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    },
    {
      label: t(`home:dashboard.rangeWeek`),
      value: [subDays(new Date(), 6), new Date()],
    },
    {
      label: t(`home:dashboard.rangeXDays`, { days: documentRetentionTime }),
      value: [subDays(new Date(), documentRetentionTime), new Date()],
    },
  ];
  useEffect(() => {
    if (documentRetentionTime) {
      setActiveDateRange([subDays(new Date(), documentRetentionTime), new Date()]);
      dispatch(
        inboxSlice.actions.patchDocumentListOptions({
          dateRange: [subDays(new Date(), documentRetentionTime), new Date()],
        })
      );
    }
  }, [dispatch, documentRetentionTime]);

  useEffect(() => {
    if (
      (userInboxIds && userInboxIds.length !== 0 && !isEqual(documentListOptions, prevDocListOptions)) ||
      currentPageIndex !== prevPageIndex
    ) {
      dispatch(inboxSlice.actions.setDocumentsLoading(true));
      getDocs();
    }
  }, [
    getDocs,
    documentListOptions,
    prevDocListOptions,
    userInboxIds,
    dispatch,
    inboxId,
    currentPageIndex,
    prevPageIndex,
  ]);
  const enabledColumns: Record<TableColumns, boolean> = {
    name: true,
    actor: true,
    actionDate: true,
    confidence: true,
    digitizedDate: false,
    docTypeId: true,
    tagTypeId: true,
    lastUserUpdate: false,
    locker: false,
    approvalChecks: false,
    actionType: true,
  };

  return (
    <div className={s.container} style={{ marginBottom: 0 }}>
      <div className={s.header}>
        <h3 className={s.title} data-testid="inbox-title">
          {t('home:historical')}
        </h3>
        <HeaderProfile />
      </div>
      <div className={s.sub_header}>
        <div className={s.search}>
          <SearchIcon />
          <input
            onChange={(e) => {
              dispatch(inboxSlice.actions.patchDocumentListOptions({ searchTerm: e.target.value }));
            }}
            value={documentListOptions?.searchTerm ?? ''}
            type="text"
            placeholder={'Search on Subject, Recipient, Sender, Actor or Document Name'}
          />
        </div>

        <div>
          <StyledSelect
            style={{
              maxHeight: 'unset',
              height: '40px',
              border: '1px solid #eeeeee',
              fontWeight: '700 !important',
            }}
            options={actionOptions}
            value={actionValue}
            onChange={(val: any) => {
              if (val.value === actionValue.value) return;
              console.log(val);
              setActionValue(val);
              if (val.value === 'all') {
                dispatch(inboxSlice.actions.patchDocumentListOptions({ action: null }));
              } else {
                dispatch(inboxSlice.actions.patchDocumentListOptions({ action: val.value }));
              }
            }}
          />
        </div>
        <InboxTags
          activeTagId={documentListOptions?.activeTagId}
          setActiveTagId={(tagId) => {
            dispatch(inboxSlice.actions.patchDocumentListOptions({ activeTagId: tagId }));
          }}
        />

        <div className={s.info}>
          {documentRetentionTime && (
            <DateRangePicker
              size={'md'}
              ranges={predefinedBottomRanges}
              shouldDisableDate={allowedRange(subDays(new Date(), documentRetentionTime), new Date())}
              placeholder="Filter Date Range"
              placement={'auto'}
              editable={false}
              cleanable={false}
              renderValue={(value) => {
                const test = predefinedBottomRanges.find(
                  (e) =>
                    isSameDate(new Date(e.value[0]), new Date(value[0])) &&
                    isSameDate(new Date(e.value[1]), new Date(value[1]))
                );
                return (
                  <div style={{ marginTop: 2 }}>
                    {test
                      ? test.label
                      : `${value[0].toLocaleDateString()} - ${value[1].toLocaleDateString()}`}
                  </div>
                );
              }}
              value={activeDateRange}
              isoWeek
              character={'  -  '}
              onChange={(e) => {
                if (!e) {
                  setActiveDateRange(undefined);
                } else {
                  dispatch(inboxSlice.actions.patchDocumentListOptions({ dateRange: e }));
                  setActiveDateRange(e);
                }
              }}
            />
          )}
        </div>
      </div>
      <InboxTable
        enabledColumns={enabledColumns}
        documentList={currentDocumentList}
        pageCount={pageCount}
        goToDocument={(docId) => {
          let query;
          if (activeDocType.docTypeId !== '') {
            query = `${activeDocType.docTypeId}`;
            if (activeDocType.subTypeId) {
              query += `&subTypeId=${activeDocType.subTypeId}`;
            }
          }
          let path = `/inbox/${inboxId}/historical/${docId}`;
          if (query) {
            path += `?docTypeId=${query}`;
          }
          navigate(path);
        }}
      />
      <InboxTableFooter pageCount={pageCount} pageSize={pageSize} />
    </div>
  );
};

export default InboxHistoricalDocuments;
